import { Empty, Tabs } from "antd";
import { ComplateLineSolutionsStyled } from "./styled/solutionCategories.styled";
import { imagesReplace } from "../../shared/utils/mixedControl";
import ButtonCustom from "../../shared/partnerComponents/button/buttonCustom";
import { memo, useCallback } from "react";
import { useRouter } from "next/router";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ComplateLineSolutions = ({ widgets }) => {
  const router = useRouter();
  const completeLineSolution = widgets?.find(
    (item) => item.code === "completeLineSolution"
  );
  const handleCompleteLine = useCallback(
    (item) => {
      const productId = item?.productID || "";
      const url = item?.url;
      if (url && productId) {
        router.push({
          pathname: `/urunler${item?.url}`,
          query: { productId },
        });
        const section = document.getElementById("navbar");
        if (section) {
          section.scrollIntoView();
        }
      }
    },
    [completeLineSolution, widgets]
  );

  const tabItems = completeLineSolution?.contents?.map((line, index) => ({
    label: line.title,
    key: index,
    children: (
      <div className="complateSolutions-tabs-image">
        {line.imagePath ? (
          <>
            <LazyLoadImage
              preview={"false"}
              src={imagesReplace(line?.imagePath)}
              height={613}
            />
            {line.imagePath && (
              <div className="button-container">
                <ButtonCustom
                  label="Keşfet"
                  className="button-container-button"
                  onClick={() => handleCompleteLine(line)}
                />
              </div>
            )}
          </>
        ) : (
          <Empty />
        )}
      </div>
    ),
  }));

  return (
    <ComplateLineSolutionsStyled className="complateSolutions">
      <div className="complateSolutions-content">
        <div className="complateSolutions-content__title newTitle">
          {completeLineSolution?.title}
        </div>
        <div className="complateSolutions-content__subTitle newSubTitle">
          {completeLineSolution?.subTitle}
        </div>
      </div>
      <div className="complateSolutions-tabs">
        <Tabs centered defaultActiveKey="0" items={tabItems} />
      </div>
    </ComplateLineSolutionsStyled>
  );
};

export default memo(ComplateLineSolutions);
